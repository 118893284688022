<template>
  <section>
    <div v-if="isLoading">
      <b-spinner size="2rem" type="grow" class="d-block mx-auto mt-3" />
    </div>
    <div v-else-if="hasErrors">
      <div class="mt-3 text-danger text-center">
        {{ $t("error-message.general-error") }}
      </div>
    </div>
    <room-details v-else-if="room.modality !== 'physical'" :room="room" @edited="handleEdited" />
    <presencial-main-view :room="room"  v-else />
  </section>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import RoomDetails from "@/views/apps/rooms/components/room-details/RoomDetails.vue";
import PresencialMainView from "@/views/apps/rooms/components/presencial-rooms/mainView.vue";
import {
  ROOMS_ACTIONS,
  ROOMS_GETTERS,
  ROOMS_STORE_MODULE_NAME,
} from "@/views/apps/rooms/constants/rooms-store-constants";
import roomsStoreModule from "@/views/apps/rooms/store/roomsStoreModule";
import ToastNotificationsMixin from "@/@core/mixins/toast-notifications/ToastNotificationsMixin";

export default {
  name: "RoomsDetailsLayout",
  components: { BSpinner, RoomDetails, PresencialMainView },
  mixins: [ToastNotificationsMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      hasErrors: false,
    };
  },
  computed: {
    room() {
      return this.$store.getters.rooms.unpaginated.filter(
        (item) => item.key === this.name
      )[0];
    },
  },
  watch: {
    name(name) {
      if (name === undefined) {
      }
      this.fetchRoom(name);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "rooms",
        page: 1,
        forceAPICall: true,
        requestConfig: {
          orderByDate: -1,
          count: 1000,
        },
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: "rooms.breadcrumb-text.rooms-list",
          to: { name: "rooms" },
        },
        {
          text: this.room
            ? this.room.name
            : "rooms.breadcrumb-text.room-details",
          active: true,
        },
      ];

      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    handleEdited() {
      setTimeout(() => {
        this.fetchData();
      }, 2000)
    },
  },
};
</script>
