<template>
  <div>
    <div v-if="isLoadingEvents">
      <b-spinner small class="mx-auto mt-3 d-block" />
    </div>
    <div v-else-if="items && items.length > 0" class="cards-container">
      <div
        ref="container"
        class="x-scroll-container w-100 py-0"
        @scroll="onScroll"
      >
        <base-events-horizontal-list
          v-slot="{ item }"
          :key="reload"
          :forced-events="items"
          :value="searchInput"
          :classifiers="finalSelectClassifier"
          :customfields="customFieldToSend"
          :reload="reload"
          :is-loading-next="isLoadingNextPage"
          :is-loading-previous="isLoadingPreviousPage"
          :events-type="type"
          :room="room"
          @events-updated="$emit('events-updated', $event)"
          @scrollOnePage="scrollOnePage"
        >
          <event-item v-if="item.isPublished" :key="item.key" :item="item" />
        </base-events-horizontal-list>
      </div>
    </div>
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="eventsPlaceholder" alt="Events with members">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("available.message", { itemName: $t("events.title") }) }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseEventsHorizontalList from '@/views/apps/events/components/BaseEventsHorizontalList.vue';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import EventItem from '@/views/apps/membershipEvents/components/EventItem.vue';

export default {
  name: 'EventsListDetail',
  components: {
    EventItem, BaseEventsHorizontalList,
  },
  props: {
    room: {
      tyep: Object,
      required: true,
    },
  },
  data() {
    return {
      searchInput: '',
      isLoadingEvents: false,
      lastLoadedPage: 1,
      perPage: 50,
      reload: 0,
      forceEvents: false,
      isLoadingNextPage: false,
      isLoadingPreviousPage: false,
      customFieldToSend: {},
      finalSelectClassifier: [],
      results: [],
      type: {
        name: 'events',
        key: 0,
        namePlural: { ca: 'Esdeveniments', es: 'Eventos', en: 'Events' },
      },
    };
  },
  computed: {
    itemsData() {
      return this.$store.getters?.communitiesOthers?.[`${this.room.key}-events`] || [];
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData?.unpaginated || [];
      return itemsToIterate;
    },
    eventsPlaceholder() {
      return EventsPlaceholder;
    },
  },
  async created() {
    await this.fetchEvents(true);
  },
  methods: {
    async fetchEvents(force = false) {
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
        orderByDate: 1,
        isEvent: true,
        displayOptions: 0,
        roomKey: this.room.key,
      };
      this.isLoadingEvents = true;
      await this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: `${this.room.key}-events`,
        page: this.lastLoadedPage,
        perPage: this.perPage,
        forceAPICall: force,
        requestConfig,
      });
      this.isLoadingEvents = false;
    },
    async onScroll(event) {
      const distanceToEnd = event.scrollWidth
        - event.scrollLeft
        - event.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.eventsData.meta;
      if (!this.isLoading && this.events.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchEvents(false);
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container?.scrollLeft;
      const toScroll = this.$refs.container?.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
        onDone: (event) => {
          this.onScroll(event);
        },
      });
    },
  },
};
</script>

<style>

</style>
