<template>
  <b-modal
    id="modal-edit-rooms"
    :title="$t('rooms.edit')"
    centered
    size="lg"
    :visible="visible"
    hide-footer
    @hide="resetForm"
    @show="showForm"
  >
    <b-form @submit.stop.prevent="handleEditItem">
      <b-form-group>
        <label for="name">{{ $t('rooms.name') }}</label>
        <b-form-input
          id="name"
          v-model="formInput.name"
          type="text"
          :placeholder="$t('form-create-item.name-placeholder')"
        />
      </b-form-group>
      <b-form-group>
        <label for="description">{{ $t('form-create-item.description') }}</label>
        <quill-editor v-model="formInput.description" />
      </b-form-group>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="typeEvent">{{ $t('rooms.modality') }}</label>
            <v-select
              id="typeEvent"
              v-model="selectedOption"
              :clearable="false"
              label="name"
              :reduce="(option) => option.key"
              :options="typeEventsOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="selectedOption !== 'physical'"
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="type">{{ $t('people.type') }}</label>
            <v-select
              id="type"
              v-model="selectedType"
              :clearable="false"
              :options="typeOptions"
              label="name"
              :reduce="(option) => option.key"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="selectedOption !== 'physical'" cols="12" md="6">
          <b-form-group>
            <label for="app">App</label>
            <v-select
              id="app"
              v-model="selectedApp"
              :clearable="false"
              :options="appsOptions"
              label="name"
              :reduce="(option) => option.key"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="selectedOption !== $t('rooms.online')" cols="12">
          <!-- Locations -->
          <b-form-group
            :label="$t('organizations.edit.locations.label')"
            label-for="profile-location"
          >
            <places-map-select
              v-model="locations"
              :placeholder="$t('organizations.edit.locations.placeholder')"
            />
          </b-form-group>
        </b-col>
        <!-- image -->
        <b-col cols="12" class="mt-1">
          <b-form-group :label="$t('form-create-item.image')" label-for="logo" class="d-inline-block w-100">
            <file-preview
              v-if="formInput.backgroundURL"
              :src="getImageResource(formInput.backgroundURL)"
              :placeholder-image="avatarPlaceholder"
              mime-type="image"
              cancelable
              @cancel="formInput.backgroundURL = null"
            />
            <file-upload
              v-if="!formInput.backgroundURL"
              v-model="itemImage"
              type="image"
              cancelable
              :placeholder="$t('form-create-item.image-button')"
              :drop-placeholder="$t('form-create-item.drop-placeholder')"
              @cancel="itemImage = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="float-right mb-50">
        <!-- Missing to implement BE -->
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isSaving"
          type="submit"
        >
          <span
            v-if="isSaving"
            class="d-flex "
          ><b-spinner
             class="mr-1"
             small
             variant="white"
             label="Loading..."
           />
            {{ $t('form.actions.save') }}
          </span>
          <span v-else>{{ $t('form.actions.save') }}</span>
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
import Placeholder from '@/assets/images/placeholders/light/rooms.svg';

export default {
  name: 'ModalEditRoom',
  components: {
    vSelect,
    quillEditor,
    FilePreview,
    FileUpload,
    PlacesMapSelect,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    visible: Boolean,
  },
  data() {
    return {
      isSaving: false,
      itemImage: null,
      imageSrc: null,
      locations: [],
      formInput: {},
      selectedOption: 'online',
      selectedApp: 1,
      selectedType: 'meeting',
      typeEventsOptions: [
        { name: this.$t('rooms.online'), key: 'online' },
        { name: this.$t('rooms.on-site'), key: 'physical' },
        { name: this.$t('rooms.hybrid'), key: 'hybrid' },
      ],
      typeOptions: [
        { name: 'Meeting', key: 'meeting' },
        { name: 'Webinar', key: 'webinar' },
      ],
      appsOptions: [
        { name: 'Zoom.us', key: 0 },
        { name: 'Conferencing', key: 1 },
        { name: 'Nectios Classroom', key: 2 },
        { name: 'Bluejeans', key: 3 },
      ],
    };
  },
  computed: {
    avatarPlaceholder() {
      return Placeholder;
    },
  },
  methods: {
    getImageResource(url) {
      return getImageResource(url);
    },
    async handleEditItem() {
      this.saving = true;
      try {
        const file = {};
        const itemDelete = {};
        if (this.itemImage) {
          file.backgroundURL = this.itemImage;
        } else if (this.formInput.backgroundURL !== null) {
          file.backgroundURL = this.formInput.backgroundURL;
        } else {
          file.backgroundURL = null;
          itemDelete.deleteBgURL = true;
        }
        const requestConfig = {
          roomKey: this.item.key,
          name: this.formInput.name,
          locations: this.locations != null && this.locations.length > 0 ? this.locations : null,
          description: this.formInput.description,
          modality: this.formInput.modality,
          app: this.formInput.app,
          type: this.formInput.type,
          ...itemDelete,
        };

        const { data } = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'rooms',
            requestConfig,
          },
          file,
        });
        this.formInput = data;
        this.$emit('editInfo', data);
        this.resetForm();
        this.saving = false;
        this.notifySuccess(this.$t('success-message.general-success-edit'));
      } catch (error) {
        this.resetForm();
        this.saving = false;
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    resetForm() {
      this.formInput = {};
      this.locations = [];
      this.$emit('close');
    },
    showForm() {
      console.log(this.item);
      this.formInput = { ...this.item };
      this.selectedOption = this.formInput.modality;
      this.selectedApp = this.formInput.app;
      this.selectedType = this.formInput.type;
      this.locations.push(this.formInput.locations);
    },
  },
};
</script>
