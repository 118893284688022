<template>
  <span>
    <div class="edit-icon-settings d-flex align-items-center">
      <feather-icon
        v-b-tooltip.hover.bottom
        icon="Share2Icon"
        :title="$t('share.tooltip')"
        size="20"
        class="text-primary share-icon zindex-1"
        :class="
          !isStaff? 'd-block mr-n50' : 'd-none d-lg-block'
        "
        @click="isShareModalVisible = true"
      />
      <b-dropdown
        v-if="isStaff"
        id="dropdown-dropleft"
        variant="link"
        right
        class="d-block"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="SettingsIcon"
            size="20"
            class="text-primary mt-25 mt-lg-0 mr-n50 mr-md-50 pr-lg-0 d-block"
          />
        </template>
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t('settings.title') }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item v-if="canEdit" @click="isEditModalVisible = true">
          <feather-icon
            icon="Edit2Icon"
            size="14"
            class="mr-50"
          />
          {{ $t('rooms.edit') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="isClassifiersModalVisible = true"
        >
          <feather-icon
            icon="LayersIcon"
            size="14"
            class="mr-50"
          />
          {{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="isCustomFieldsModalVisible = true"
        >
          <feather-icon
            icon="ToolIcon"
            size="14"
            class="mr-50"
          />
          {{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
        <!--         <b-dropdown-item
          v-if="canEdit"
          v-b-modal.contact-modal
        >
          <feather-icon
            icon="UserIcon"
            size="14"
            class="mr-50"
          />
          Editar responsable
        </b-dropdown-item> -->
        <b-dropdown-item
          v-if="canDelete"
          @click="isUnshareModalVisible = true"
        >
          <feather-icon
            icon="Trash2Icon"
            class="mr-50"
            size="14"
          />
          Borrar Sala
        </b-dropdown-item>
        <b-dropdown-item
          class="d-block d-lg-none"
          @click="isShareModalVisible = true"
        >
          <feather-icon
            icon="Share2Icon"
            size="14"
            class="mr-50"
          />
          {{ $t("share.tooltip") }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="Object.values(item).length > 0 ">
      <!-- DETAIL CARD IMAGE AND INFO -->
      <b-row
        class="px-lg-2 px-1 pb-2"
        style="max-width:1300px; margin:auto; min-height: 100% !important;"
      >
        <!-- TOP LEFT: IMAGE -->
        <b-col lg="6" sm="12" class="px-lg-1 px-0 mt-2 h-auto">
          <b-card
            overlay
            :class="
              isTablet
                ? 'membership-event-image'
                : 'membership-event-image membership-event-image-desktop mb-0'
            "
            :img-src="getImageSrc(item.backgroundURL) || Placeholder"
            img-alt="Room Image"
            style="background-color: white"
            :style="isTablet ? 'max-height:300px' : 'height: 100% !important; max-height: 600px !important; min-width:100%' "
          />
        </b-col>

        <!-- TOP RIGHT: CARD INFO -->
        <b-col
          lg="6"
          sm="12"
          class="px-lg-2 px-0 mt-lg-2 mt-1  overflow-hidden"
          :style="isMobile ? '' : 'min-height: 100% !important;'"
        >
          <b-card
            class="d-flex flex-column justify-content-between"
            :class="isMobile ? 'mb-1 p-2' : 'mb-0 px-3 pt-3 pb-3'"
            :style="
              isMobile
                ? 'min-height:350px'
                : 'height: 100% !important; min-height:350px; max-height: 600px !important;'
            "
            no-body
          >
            <!-- Title and attendees -->
            <div>
              <div>
                <p class="text-muted font-small-2 mb-0">
                  {{ $t('rooms.page-title.room-details') }}
                </p>
                <h3
                  class="text-dark font-weight-bold mb-0 mt-50"
                  style="font-weight: 600 !important"
                >
                  {{ translate(item.name) }}
                </h3>
                <p v-if="item.headline" class="mb-0 html-text-ellipsis-2" :class="showModality?'':'mb-2'">
                  {{ translate(item.headline) }}
                </p>
              </div>
              <p class="text-secondary my-1 mb-2 text-ellipsis-lines-1">
                <b-icon-camera-video
                  v-if="!item.modality || item.modality === 'online'"
                  font-scale="1.05"
                  class="mr-50"
                />
                <b-icon-geo-alt v-else font-scale="1.05" class="mr-25" />
                <span v-if="item.modality">{{ $t(`community-others.on-site`) }}</span>
                <span v-else>{{ $t("community-others.online") }}</span>
                <span v-if="item.locations && Object.values(item.locations).length > 0">
                  <span class="mx-50">-</span>
                  <b-link
                    href="#locations"
                    class="text-secondary"
                  >
                    {{ item.locations.address }}
                  </b-link>
                </span>
              </p>
            </div>
            <b-button variant="primary" class="w-100" @click="$emit('viewRoom')">
              <span v-if="isStaff || canEdit">{{ $t('rooms.manage') }}</span>
              <span v-else>{{ $t('rooms.manage') }}</span>
            </b-button>
          </b-card>
        </b-col>
      </b-row>

      <!-- DETAIL INFO -->
      <div
        v-if="item"
        class="py-2 mx-xl-n3 mx-n2"
        :class="isMobile ? '' : 'mt-2'"
        style="background: white; min-height:40vh"
      >
        <b-row class="px-2" style="max-width: 1300px; margin: auto">
          <b-col
            cols="12"
            class="d-flex mb-1 pb-2 px-0  px-lg-2 justify-content-between justify-content-lg-start border-bottom"
            :class="isMobile ? 'justify-content-between' : ''"
          >
            <div
              v-for="(page, index) of menu"
              :key="index"
              class="banner-selector-background d-lg-flex align-items-center mt-50 px-75 px-md-1 px-lg-2 pointer"
              :class="{
                'banner-selector-background--active': menuPage === page.key,
                'ml-md-3': index > 0
              }"
              @click="menuPage = page.key"
            >
              <feather-icon
                v-if="!isMobile"
                :icon="page.icon"
                size="20"
                class="d-block mx-auto mb-md-1"
                :class="menuPage === page.key ? 'text-primary' : 'menu-text'"
              />
              <p
                class="h5 mb-50 mb-lg-1 ml-lg-1"
                :class="menuPage === page.key? 'text-primary' : 'menu-text'"
              >
                {{ page.title }}
              </p>
            </div>
          </b-col>
          <!-- ABOUT -->
          <b-col v-if="menuPage === 'about'">
            <about-room :item="item" item-type="rooms" />
          </b-col>
          <!-- EVENTS -->
          <b-col v-if="menuPage === 'events'">
            <events-list-detail
              :room="item"
            />
          </b-col>
          <!-- CONTACT -->
          <b-col v-if="menuPage === 'contact'" cols="12" class="pt-2 pb-3 d-flex">
            <contact
              v-if="item"
              :item="item"
              type="rooms"
              type-single="room"
              @handleEditItem="handleEditItem"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- share Modal -->
    <share-modal
      v-if="item.key"
      v-model="isShareModalVisible"
      :can-share="canShare"
      :item-name="item.name"
      model-type="rooms"
      type="rooms"
      :model-key="item.key"
    />
    <!-- classifiers -->
    <classifiers-modal
      v-model="isClassifiersModalVisible"
      :item-key="item.key"
      morph-type="rooms"
      model-type="room"
      :classifiers="classifiers"
      @profileUpdated="fetchClassifiers"
    />
    <!-- sortedCustomFields -->
    <customfields-modal
      v-model="isCustomFieldsModalVisible"
      :item="item"
      morph-type="room"
      @profileUpdated="updateCustomFields"
    />
    <!-- Edit modal -->
    <modal-edit-room
      v-if="item"
      v-model="isEditModalVisible"
      :item="item"
      @close="isEditModalVisible = false"
      @editInfo="successEdit"
    />
    <!-- Contact modal -->
    <!--     <b-modal
      v-if="item"
      id="contact-modal"
      hide-footer
      title="Change Contact"
      size="xl"
      centered
      no-close-on-backdrop
    >
      <change-contact
        :is-from-settings="false"
        :item="item"
        type="rooms"
        @handleEditItem="handleEditItem"
      />

    </b-modal> -->
    <!-- Delete modal -->
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="room"
      @remove-item="handleRemoveItem"
    />
  </span>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import moment from 'moment';
import ChangeContact from '@/@core/components/contact/ChangeContact.vue';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import AboutRoom from '@/views/apps/rooms/components/presencial-rooms/components/AboutRoom.vue';
import Contact from '@core/components/contact/Contact.vue';
import EventsListDetail from '@/views/apps/rooms/components/presencial-rooms/components/EventsListDetail.vue';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ModalEditRoom from '@/views/apps/rooms/components/presencial-rooms/components/ModalEditRoom.vue';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';

export default {
  name: 'RoomInfo',
  components: {
    ShareModal,
    EventsListDetail,
    AboutRoom,
    Contact,
    ModalEditRoom,
    UnshareModal,
    ClassifiersModal,
    CustomfieldsModal,
    ChangeContact,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSaveCalendar: false,
      Placeholder,
      isEditModalVisible: false, //
      menuPage: 'about',
      isCustomFieldsModalVisible: false,
      isUnshareModalVisible: false,
      isClassifiersModalVisible: false,
      isContactModalVisible: false,
      // menu
      menu: [
        {
          key: 'about', title: this.$t('events.event-details.about.title'), icon: 'AirplayIcon', order: 0,
        },
        {
          key: 'events', title: this.$t('events.title'), icon: 'CalendarIcon', order: 1,
        },
        /*         {
          key: 'contact', title: this.$t('services.contact.title'), icon: 'MailIcon', order: 2,
        } */],
      isShareModalVisible: false,

    };
  },
  computed: {
    isTablet() {
      return window.innerWidth < 993;
    },
    isMobile() {
      return window.innerWidth < 576;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    classifiers() {
      if (this.$store.getters.classifiers.room) {
        return this.$store.getters.classifiers.room.unpaginated;
      }
      return [];
    },
    canEdit() {
      return checkPermissions(
        'update',
        'room',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    canDelete() {
      return (

        checkPermissions(
          'delete',
          'room',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canShare() {
      return checkPermissions('share', 'rooms', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
  },
  watch: {
    menu(value) {
      return value;
    },
    isMobile(value) {
      return value;
    },
    isTablet(value) {
      return value;
    },

  },
  async created() {
    await this.fetchClassifiers();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    timestampToHour(timestamp) {
      return moment(timestamp).format('HH:mm');
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    successEdit(data) {
      this.item = data;
    },
    handleEditItem() {
      this.$bvModal.hide('contact-modal');
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: 'room',
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          roomKey: this.item?.key,
        },
      });
      // this.updateBreadcrumbs();
    },
    deleteItem() {
      this.isUnshareModalVisible = true;
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('deleteRooms', {
            item: {
              tableName: 'rooms',
              key: this.item.key,
            },
            type: 'rooms/delete',
          });
          this.$router.push({
            name: 'rooms',
            params: { communityId: this.$route.params.communityId },
          });
          this.notifySuccess(this.$t('success-message.general-success-delete'));
        } catch {
          this.notifyError(this.$t('error-message.general-error'));
        }
      }
    },
    updateCustomFields(items) {
      if (typeof items === 'object') {
        const value = Object.values(items)[0];
        const key = Object.keys(items)[0];
        this.item.custom.forEach((row) => {
          if (row.key === key) {
            row.value = value;
          }
        });
      } else if (Array.isArray(items)) {
        this.items.forEach((item) => {
          const value = Object.values(item)[0];
          const key = Object.keys(item)[0];
          this.item.custom.forEach((row) => {
            if (row.key === key) {
              row.value = value;
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.menu-text{
  color:lighten($dark, 40%);
}
.membership-event-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fd;
  > div{
    min-width: 100%;
    min-height: 100%
  }
}
.membership-event-image img {
  border-radius: 0;
}
.membership-event-image-desktop img {
  min-height: 100%
}
.card-img {
  height: 200px !important;
}
.event-card {
  min-height: 60vh;
}
.dot {
  color: #48a2a9;
  margin: 0 5px;
}
.gray {
  color: #82818d;
  font-size: 16px;
}
.filter-select {
  min-width: 150px;
  @include media-breakpoint-up(md) {
    min-width: 250px;
  }
}
.edit-icon-settings {
  position: absolute;
  right: 10px;
  top: -50px;
  cursor: pointer;
}
</style>
