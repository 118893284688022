<template>
  <div class="rounded border p-3 bg-white">
    <h2>{{ room.name }}</h2>
    <p>Selecciona una evento:</p>
    <div>
      <!-- Items Loop -->
      <b-row>
        <b-col
          v-for="item in items"
          :key="item.key"
          :class="
            item.isPublished || (!item.isPublished && canAdd)
              ? 'mb-sm-50'
              : 'd-none'
          "
        >
          <event-item
            v-if="item.isPublished || (!item.isPublished && canAdd)"
            :item="item"
            @selectedEvent="$emit('selectedEvent', item)"
          />
        </b-col>
        <!-- Load More items -->

        <div
          v-if="items && items.length < itemsData.meta.total"
          class="w-100 float-left"
        >
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t("action.load-more") }}
          </b-button>
          <b-button v-else disabled class="center-x my-3" variant="link">
            <b-spinner variant="primary" label="Loading..." />
          </b-button>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import EventItem from "./EventItem.vue";
export default {
  components: {
    EventItem,
  },
  name: "RoomsEventList",
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingNextPage: false,
      lastLoadedPage: 1,
    };
  },
  computed: {
    itemsData() {
      return this.$store.getters.communitiesOthers?.[`${this.room.key}-events`];
    },
    items() {
      return this.itemsData?.unpaginated;
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    async fetchData(force = true) {
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
        orderByDate: 1,
        isEvent: true,
        roomKey: this.room.key,
      };
      await this.$store.dispatch("getItems", {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: `${this.room.key}-events`,
        page: this.lastLoadedPage,
        perPage: this.perPage,
        forceAPICall: force,
        requestConfig,
      });
    },
    async handleLoadOfNewItems(){
        this.isLoadingNextPage = true;
        this.lastLoadedPage++;
        await this.fetchData();
        this.isLoadingNextPage = false;
    }
  },
};
</script>
