<template>
  <section>
    <b-col cols="12" class="my-2 d-flex justify-content-between flex-wrap">
      <b-col cols="12" :md="item.custom || (classifiers && classifiers.length > 0)?'6':'12'" class="px-xl-50">
        <p
          v-if="translate(item.description) && translate(item.description)!==''"
          class="event-detail-descripction pr-2"
          v-html="translate(item.description)"
        />
        <p v-else>
          {{ $t(`${itemType}.about.no-description`) }}
        </p>
      </b-col>
      <b-col
        v-if="item.custom || (classifiers && classifiers.length > 0)"
        cols="12"
        md="6"
        class="pl-2"
      >
        <div>
          <!--Custom fields-->
          <b-row
            v-for="(field, index) in item.custom"
            :key="index"
            class="mb-1"
          >
            <b-col v-if="field.value" class="font-weight-bold">
              {{ translate(field.customFieldName) }}
            </b-col>
            <b-col>
              <p class="mb-0">
                {{ field.value }}
              </p>
            </b-col>
          </b-row>
          <!-- Classifiers -->
          <!--     <template
            v-if="classifiers && classifiers.length > 0"
            v-key="classifiers"
            class="my-50"
          >
            <div
              v-for="(value, name) in sortedClassifiers"
              :key="value.key"
              class="mb-1"
            >
              <div class="w-100 font-weight-bold">
                {{ name }}:
              </div>
              <span v-for="v in value" :key="v.index">
                <b-badge class="my-50 mr-50" :style="classifierStyle(v)">
                  {{ translate(v.name) }}
                </b-badge>
              </span>
            </div>
          </template> -->
        </div>
      </b-col>
    </b-col>
    <b-col
      v-if="item.locations"
      cols="12"
      class="px-lg-2 mt-lg-2 border-top pt-2 mb-2"
    >
      <h4 id="locations" class="text-dark mb-2 font-weight-600">
        {{ $t("community-others.locations") }}
      </h4>
      <!-- Host -->
      <b-row class="mb-2">
        <b-col>
          <h6>{{ item.locations.locality }}</h6>
          <p>{{ item.locations.address }}</p>
        </b-col>
      </b-row>
      <b-card no-body class="h-100 overflow-hidden" style="min-height: 360px">
        <places-map
          :locations="locations"
          :in-container="true"
          style="height: 500px"
        />
      </b-card>
    </b-col>
  </section>
</template>

<script>
import PlacesMap from '@core/components/places-map-input/PlacesMap.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { dateValidator } from '@/@core/utils/validations/validators';
import { toAbsoluteURL } from '@core/utils/url-utils';

export default {
  name: 'AboutDetail',
  components: {
    PlacesMap,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSmall: false,
      countries: [],
      services: [],
      locations: [],
    };
  },
  computed: {
    cFields() {
      return this.$store.getters.customFields;
    },
    currentCollectiveKey() {
      // latamfm
      return (
        this.$store.getters.currentCollective.key
        === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5'
      );
    },
    hasInfo() {
      return (
        this.item?.emails?.length > 0
        || this.item?.phones?.length > 0
        || this.item?.links?.length > 0
        || Object.values(this.sortedClassifiers).length > 0
      );
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[this.translate(item.typeName)]
        ) {
          newObject[this.translate(item.typeName)] = [item];
        } else {
          newObject[this.translate(item.typeName)].push(item);
        }
      });
      return newObject;
    },
    classifiersData() {
      return this.item?.classifiers;
    },
    classifiers() {
      if (this.classifiersData) {
        return this.classifiersData.reduce((acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        }, []);
      } return [];
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.item.locations) {
      this.locations.push(this.item.locations);
    }

  //  await this.fetchCustomFields();
    // await this.fetchClassifiers();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    hasSocialIcon(site) {
      // Social media icons: Instagram, Facebook, Linkedin, Youtube, Twitter. The rest with globe icon
      // Social media names obtained from @copernicsw/community-common/link/
      // TODO: Replace by enum when commos is updated.
      return [
        'Instagram',
        'Facebook',
        'Linkedin',
        'Youtube',
        'Twitter',
      ].includes(site);
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 800;
    },
    toAbsoluteURL,
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.map-container > .map {
  height: 100%;
  position: initial !important;
}
.classifier-content {
  width: 85%;
}
</style>
